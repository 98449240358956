<template>
  <div class="container">
    <div class="header"><span class="line"></span> 店员管理</div>
    <div class="opt">
      <a-button type="primary" icon="plus" @click="visibleAdd=true">添加账号</a-button>
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="姓名" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-input v-model="queryParam.userName" placeholder="请输入姓名"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="状态" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-select style="width: 200px" placeholder="请选择状态" v-model="queryParam.status">
                <a-select-option value="100201">正常</a-select-option>
                <a-select-option value="100202">停用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="freeze(record)" v-if="record.status==='100201'">冻结员工</a>
          <a @click="refreeze(record)" v-else>解冻员工</a>
          <a-divider type="vertical" v-if="record.status==='100201'" />
          <a @click="reset(record)" v-if="record.status==='100201'">关联店铺</a>
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="添加账号"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
        <a-form-item label="邮箱">
          <a-input
            placeholder="请填写邮箱"
            v-decorator="['email', { rules: [{ required: true, message: '请填写邮箱!' }] }]"
          />
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
            placeholder="请填写姓名"
            v-decorator="['userName', { rules: [{ required: true, message: '请填写姓名!' }] }]"
          />
        </a-form-item>
        <a-form-item label="权限类型">
          <a-select
            v-decorator="[
              'roleType',
              { rules: [{ required: true, message: '请选择!' }] },
            ]"
            placeholder="请选择权限类型"
            @change="handleSelectChange"
          >
            <a-select-option value="102011">
              普通用户
            </a-select-option>
            <!-- <a-select-option value="102001">
              管理员
            </a-select-option> -->
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 关联店铺 -->
    <a-modal title="关联店铺" width="100%" class="detail-modal" :footer="null" :visible="visibleDetailModal" @cancel="visibleDetailModal = false;">
      <Detail v-if="visibleDetailModal" :id="currentId"></Detail>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import Detail from './components/detail';
import {
  getUserInfoList,
  addEnterpriseAccount,
  freezeEnterpriseAccount,
  unLock
} from '@/api/api';
export default {
  components: {
    STable,
    Detail
  },
  data () {
    return {
      currentId: '',
      visibleDetailModal: false,
      raskColumns: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
        },
        {
          title: '姓名',
          dataIndex: 'userName',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text==='100201' ? '正常' : '冻结'
        },
        {
          title: '权限',
          dataIndex: 'roleType',
          customRender: text => text==='102011' ? '普通用户' : '管理员'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        enterpriseName: '',
        userName: '',
        status: undefined,
      },
    }
  },
  methods: {
    // 获取列表
    raskData (parameter) {
      return getUserInfoList(Object.assign(parameter,this.queryParam,{roleType:'102011'})).then(res => {
        res.result.data = res.result.records
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        enterpriseName: '',
        userName: '',
        status: undefined,
      };
      this.handleToSearchEnterprise();
    },
    // 添加
    handleOkAdd (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoadingAdd = true
          addEnterpriseAccount(values).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.table.refresh(true)
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.visibleAdd = false
    },
    // 冻结
    freeze (record) {
      let that = this
      this.$confirm({
        title: '确定冻结' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseAccount({id:record.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.handleToSearchEnterprise()
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 解冻
    refreeze (record) {
      let that = this
      this.$confirm({
        title: '确定解冻' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            unLock({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.handleToSearchEnterprise()
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 关联店铺
    reset (record) {
      this.currentId = record
      this.visibleDetailModal = true
    },
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    margin-top: -100px;
    .ant-modal-body {
      height: calc(100%-55px);
      padding: 0;
    }
  }
}
</style>